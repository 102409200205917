export function checkENV(env, country) {
  let terms = env.countries.split(",").map((code) => code.toUpperCase());
  for (var i = 0; i < terms.length; i++) {
    if (terms[i] == "*") return true;
    if (terms[i][0] == "!" && terms[i].slice(1) == country) return false;
    if (terms[i] == country) return true;
  }
  return false;
}

export function changeIdentity(identity, callback = () => {}) {
  localStorage.setItem("APP_IDENTITY", identity);
  // localStorage.removeItem("i18nMessages");
  localStorage.removeItem("vuex-multi-tab");
  localStorage.removeItem("unverified_client_restrictions");
  localStorage.removeItem("client");
  localStorage.removeItem("lead");
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("ip");
  callback();
}

export default function (localStorageKey, appENV, country) {
  let identity_name = localStorage.getItem(localStorageKey);
  let identity = appENV.find((env) => env.name == identity_name);
  if (!identity_name || !identity) {
    appENV.forEach((env) => {
      const valid = checkENV(env, country);

      if (valid && !identity) {
        identity = env;
      }
    });
    localStorage.setItem(localStorageKey, identity.name);
  }
  return identity;
}
