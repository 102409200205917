import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";

Vue.use(VueRouter);

const appENV = JSON.parse(process.env.VUE_APP_ENV || "[]");

const routes = [
  {
    path: "/",
    component: Home,
    name: "home",
  },
  {
    path: "/trading-platform",
    name: "TradingPlatform",
    component: () => import("../views/trading-platform/TradingPlatform.vue"),
  },
  {
    path: "/tools",
    redirect: (to) => {
      return { name: "EconomicCalendar" };
    },
  },
  {
    path: "/tools/economic-calendar",
    name: "EconomicCalendar",
    component: () => import("../views/economic-calendar/EconomicCalendar.vue"),
  },
  {
    path: "/tools/technical-views",
    name: "TechnicalViews",
    component: () => import("../views/technical-views/TechnicalViews.vue"),
  },
  {
    path: "/tools/tc-alpha-generator",
    name: "TcAlphaGenerator",
    component: () => import("../views/tc-alpha-generator/TcAlphaGenerator.vue"),
  },
  {
    path: "/tools/cp-widgets",
    name: "CpWidgets",
    component: () => import("../views/cp-widgets/CpWidgets.vue"),
  },
  {
    path: "/company",
    redirect: (to) => {
      return { name: "AboutUs" };
    },
  },
  {
    path: "/company/affiliates",
    name: "Affiliates",
    component: () => import("../views/affiliates/Affiliates.vue"),
  },
  {
    path: "/accounts-and-fees",
    name: "AccountsAndFees",
    component: () => import("../views/accounts-and-fees/AccountsAndFees.vue"),
  },
  {
    path: "/company/contact-us",
    name: "ContactUs",
    component: () => import("../views/contact-us/ContactUs.vue"),
  },
  {
    path: "/company/careers",
    name: "Careers",
    component: () => import("../views/careers/Careers.vue"),
  },
  {
    path: "/markets",
    name: "Markets",
    component: () => import("../views/markets/Markets.vue"),
    props: true,
  },
  {
    path: "/company/legal-documents",
    name: "LegalDocuments",
    component: () => import("../views/legal-documents/LegalDocuments.vue"),
  },
  {
    path: "/company/legal-documents/:document",
    name: "LegalDocument",
    component: () => import("../views/legal-documents/LegalDocument.vue"),
  },
  {
    path: "/company/about-us",
    name: "AboutUs",
    component: () => import("../views/about-us/AboutUs.vue"),
  },
  {
    path: "",
    name: "outer-parent",
    redirect: "/home",
  },

  {
    path: "/company/careers/:job",
    component: () => import("../views/job-application/JobApplication.vue"),
  },
  {
    path: "/complaint-form",
    name: "ComplaintForm",
    component: () => import("../views/complaint-form/ComplaintForm.vue"),
  },

  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/forgot-password/ForgotPassword.vue"),
  },

  ...appENV.map((env) => ({
    path: "/" + env.shortname + "*",
  })),

  {
    path: "*",
    name: "PageNotFound",
    component: () => import("../views/error/Error404.vue"),
  },

  {
    path: "/not-avaibled-country",
    name: "NotAvaibledCountry",
    component: () => import("../views/error/Error404.vue"),
  },
];

import { changeIdentity } from "@/libs/MultipleIdentities.js";
import { checkENV } from "../libs/MultipleIdentities";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  let isAvaibledCountry = false;
  for (const env of appENV) {
    const valid = checkENV(env, localStorage.getItem("country"));
    if (valid) {
      isAvaibledCountry = true;
      break;
    }
  }
  if (!isAvaibledCountry && to.name != "NotAvaibledCountry") {
    next({ name: "NotAvaibledCountry" });
  } else {
    appENV.forEach((env) => {
      if (to.fullPath.startsWith("/" + env.shortname)) {
        if (env.name != localStorage.getItem("APP_IDENTITY")) {
          changeIdentity(env.name);
        }
        next(to.fullPath.slice(env.shortname.length + 1));
      }
    });
    next();
  }
});

// Facebook track page view
router.afterEach((to) => {
  window.fbq("track", "ViewContent", {
    content_category: "page",
    content_name: to.name,
  });
  return true;
});

export default router;
